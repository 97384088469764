body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  width: 1000px;
  margin: auto;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

:root {
  /* --amplify-primary-color:	#ff9900; */
  --amplify-primary-color: #0000ff;
  /* --amplify-primary-contrast:	#ffffff; */
  --amplify-primary-contrast:	#ffffff;
  /* --amplify-primary-tint:	#ffac31; */
  --amplify-primary-tint:	#0051ff;
  /* --amplify-primary-shade:	#e88b01; */
  --amplify-primary-shade:	#0000b3;

  /* --amplify-secondary-color:	#152939;
  --amplify-secondary-contrast: #ffffff;
  --amplify-secondary-tint:	#31465f;
  --amplify-secondary-shade:	#1F2A37;
  --amplify-tertiary-color:	#5d8aff;
  --amplify-tertiary-contrast: #ffffff;
  --amplify-tertiary-tint:	#7da1ff;
  --amplify-tertiary-shade:	#537BE5;
  --amplify-grey:	#828282;
  --amplify-light-grey:	#c4c4c4;
  --amplify-white:	#ffffff;
  --amplify-red:	#dd3f5b; */
}

amplify-authenticator {
  --container-height: unset !important;
}

#tokenInput:focus {
  border: 1px solid #0000ff !important;
  outline: none;
}